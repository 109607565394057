import * as React from "react";

// markup
const NotFoundPage = () => {
  return (
    <main>
      <h1>Page Note Found!</h1>
    </main>
  );
};

export default NotFoundPage;
